@title-height: 90px;
@nav-height: 70px;
@nav-line-height: 70px;

@menu-height: @title-height + @nav-height;


.nav-container {
  -webkit-backface-visibility: hidden;
  max-width: 100%;
}

nav {
  -webkit-backface-visibility: hidden;
  max-width: 100%;

  .title-bar {
    height: @title-height;

    position: relative;
  }

  ul {
    margin-bottom: 0;
  }

  .nav-bar {
    height: @nav-height;
    max-height: @nav-height;
    line-height: @nav-line-height;
    //border-bottom: 5px solid @color-primary;

    .btn {
      margin: 0;
      height: auto;
    }

    a {
      display: inline-block;
      //height: @nav-height;
    }

    .module {
      padding: 0 32px;
    }

    .module-group {
      margin-left: 0;
      margin-right: 0;
      &.sl-wrapper{
        width: 20%;
      }
      &.mmenu{
        width: 59%;
      }
      &.lan-wrapper{
        width: 20%;
      }
    }

    .module, .module-group {
      display: inline-block;

      &.left {
        float: left;
      }

      &.right {
        float: right;
      }
      &.center {

      }

    }

  }
}


.logo {
  max-height: 90%;
}

.logo-light {
  display: none;
}


.title-bar {
  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

/*nav.bg-dark .logo-light {
  display: inline;
}*/

/*nav.bg-dark .logo-dark {
  display: none;
}*/

/*.nav-utility {
  height: 45px;
  line-height: 43px;
  border-bottom: 1px solid #ccc;
  overflow: hidden;

  i {
    position: relative;
    top: 1px;
  }
}*/

.has-dropdown {
  padding-right: 18px;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 11px;
    content: "\f078";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
}

.menu {
  width: 100%;
  height: @nav-height;
  font-family: @heading-font, "Helvetica Neue", Helvetica, Arial, sans-serif;
  &.inline-block {
    width: auto;
  }

  li{
    a, span {
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 400;
      color:@color-white;
      .transition(0.3s);
      max-width: 100%;
      white-space: normal;
      &:hover {
        color: @color-footer-bg !important;
      }
    }
  }

  > li {
    margin-right: 40px;
    float: left;
    position: relative;
    .transition(0.3s);
    opacity: 1;

    &:last-child {
      margin-right: 0;
      > ul {
        right: 0;

        ul {
          left: auto;
          right: 100%;
        }

      }
    }

    &.active{
      > a, span{
        color: @color-white;
      }
    }

    ul {
      width: 200px;
      padding: 30px 0;
      background: @color-white;
      position: absolute;
      z-index: 99;
      opacity: 0;
      .transition(0.3s);
      .translateY(10px);
      visibility: hidden;
      margin-top: -6px;

      li{
        a, span{
          color: @color-dark-gray;
          height: auto;
          padding: 6px 24px;

        }
        &.active{
          a:after, span:after{
            content: '';
            display: block;
            width: 100%;
            height: 3px;
            background-color: @color-primary;
          }
        }

      }
    }

    > ul {

      > li {
        position: relative;
        line-height: 24px;
        width: 100%;
        vertical-align: top;
        i {
          display: inline-block;
          margin-right: 2px;
        }

        > ul {
          left: 100%;
          top: 0;
        }
      }

      .has-dropdown{
        &:after {
          color: @color-white;
          top: 5px;
          right: 24px;
          content: "\e649";
        }
      }

    }

    &:hover{
      > ul{
        opacity: 1;
        .translateY(0px);
        visibility: visible;
      }
    }
  }
}

.fixed .menu li a, .fixed .menu li span {
  color: @color-dark-gray !important;
  //opacity: 0.5;
}

.menu > li > ul > li:hover > ul, .has-dropdown:hover .mega-menu ul {
  opacity: 1;
  .translateY(0px);
  visibility: visible;
}

.mega-menu {
  width: auto !important;
  white-space: nowrap;
  line-height: 24px;
  ul {
    position: relative !important;
    left: auto !important;
    padding: 0 !important;
  }

  .title {
    letter-spacing: 1px;
    color: @color-dark-gray;
    display: inline-block;
    padding: 6px 24px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
  }

  > li {
    width: 200px !important;
    overflow: hidden;
    display: inline-block;

    &.menu-image {
      vertical-align: bottom;
    }
  }
}

.module.widget-handle {
  border-left: 1px solid #ccc;
  padding: 0 24px;
  cursor: pointer;
  position: relative;
  .disable-select{
    margin: 0;
  }
}

nav.nav-centered .module.widget-handle {
  border: none !important;
}

@media all and (max-width: 1100px) {
  .module.widget-handle {
    padding: 0 16px;
  }
}

.module.widget-handle i {
  font-size: 24px;
  line-height: @nav-line-height;
  font-weight: normal;
  .transition(0.3s);
}

/*.module.widget-handle:hover i, .module.active i {
  color: @color-primary;
}*/

.widget-handle .function {
  .cast-shadow;
  cursor: default;
  width: 200px;
  background: @bg-dark;
  position: absolute;
  z-index: 99;
  opacity: 0;
  .transition(0.3s);
  .translateY(10px);
  visibility: hidden;
  margin-top: -2px;
  right: 0;
}

.module.widget-handle:hover .function {
  opacity: 1;
  .translateY(0px);
  visibility: visible;
}

.module.widget-handle .title {
  font-family: @ff-interstate;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  display: none;
  opacity: .5;
  .transition(0.3s);
}

.module.widget-handle .title:hover {
  opacity: 1;
}

/*
.widget-handle .cart {
  position: relative;
}
*/

/*.widget-handle .cart .label {
  width: 17px;
  height: 17px;
  font-size: 10px;
  line-height: 17px;
  padding: 0;
  text-align: center;
  position: absolute;
  background: @color-primary;
  top: 10px;
  right: -10px;
  border-radius: 50%;
}*/

/*.widget-handle .search-form {
  padding: 8px;
  display: inline-block;
  width: 100%;
  line-height: 50px;
}*/

/*.widget-handle .search-form input {
  margin: 0;
  font-size: 16px;
}*/

nav .widget {
  margin: 0;
  padding: 24px;
}

nav .widget .title {
  display: none !important;
}

nav .widget, nav .widget a:not(.btn) {
  color: @color-white;
}

nav .widget hr {
  border-color: @color-primary;
  margin-bottom: 16px;
}

nav .widget hr:first-of-type {
  display: none;
}

/*.cart-widget-handle .function {
  width: auto;
  background: @color-white;
}*/

/*.cart-widget-handle .function span {
  color: #222 !important;
}*/

/*
nav .cart-overview {
  min-width: 300px;
  margin-bottom: 16px;
}
*/

/*
nav .cart-overview a {
  height: auto;
}
*/

.language {
  .menu > li ul {
    max-width: 150px;
  }
  &.right{
    position: relative;
    @media all and (min-width: 991px){
      position: absolute;
    }

  }
}

.nav-open {
  max-height: 10000px !important;
  height: auto !important;
}

.nav-open .mobile-toggle {
  border-bottom: 1px solid #ccc;
}

@media all and (max-width: 1120px) {
  .menu > li {
    margin-right: 24px;
  }
}

.has-offscreen-nav .main-container {
  .transition(0.4s);
}

.offscreen-container {
  position: fixed;
  .translateX(200%);
  width: 50%;
  top: 0;
  height: 100%;
  min-height: 100vh;
  z-index: 20;
  .transition(0.4s);
  overflow: hidden;
}

.offscreen-container.reveal-nav {
  .translateX(100%);
}

.main-container.reveal-nav {
  .translateX(-50%);
}

.offscreen-left .offscreen-container {
  .translateX(-50%);
}

.offscreen-left .offscreen-container.reveal-nav {
  .translateX(0%);
}

.offscreen-left .main-container.reveal-nav, .offscreen-left nav.reveal-nav {
  .translateX(50%) !important;
}


.offscreen-container .close-nav {
  position: absolute;
  right: 24px;
  top: 16px;
  z-index: 24;
  font-size: 20px;
  .transition(0.3s);
  opacity: .5;
}

.offscreen-container .close-nav:hover {
  opacity: 1;
}

/*.offscreen-container.bg-dark .close-nav i {
  color: @color-white;
}*/

@media all and (max-width: 990px) {
  .offscreen-container {
    width: 100vw;
  }

  .offscreen-container.reveal-nav {
    .translateX(0vw);
  }

  .main-container.reveal-nav {
    transform: none !important;
  }
}

@media all and (max-width: 990px) {

  nav.fixed {
    position: absolute !important;
    opacity: 1 !important;
    visibility: visible !important;
  }

  nav.outOfSight {
    .translateY(0px) !important;
    .transition(0.3s);
  }

  .nav-bar, .nav-bar .module-group, .nav-bar .module {
    height: auto;
    overflow: hidden;
  }

  .nav-bar .module {
    padding: 0 16px;
  }

  .nav-bar .module-group {
    width: 100%;
    padding: 16px 0;
  }

  .nav-bar .module-group .module {
    display: block;
    float: none;
    width: 100%;
  }

  nav.nav-centered .logo {
    margin: 24px 0 16px 0;
    max-height: 40px;
  }

  .menu {
    height: auto;
  }

  .menu.inline-block {
    width: 100%;
  }

  .menu a {
    height: auto;
    line-height: 24px;
    padding: 4px 0;
  }

  .menu li {
    line-height: 24px;
    float: none;
    display: block;
    width: 100%;
    max-width: 100%;
  }

  .menu > li ul {
    position: relative;
    width: 100%;
    opacity: 1;
    visibility: visible;
    .translateY(0px);
    left: 0;
  }

  .menu > li > ul {
    position: relative;
    opacity: 1;
    visibility: visible;
    display: none;
    .translateY(0px);
  }

  .menu > li > ul > .has-dropdown:after {
    content: "\e64b";
  }

  .menu > li > ul > li > ul {
    left: 0;
    display: none;
    padding: 0;
  }

  .menu > li > ul li a, .mega-menu .title {
    padding: 4px 16px;
  }

  .has-dropdown .has-dropdown li {
    padding-left: 18px;
  }

  .has-dropdown {
    padding-right: 0;
  }

  .mega-menu {
    margin-left: 0 !important;
  }

  .mega-menu li {
    width: 100% !important;
  }

  .toggle-sub > ul, .toggle-sub .mega-menu ul {
    display: block !important;
  }

  .module.widget-handle {
    border-left: none;
    border-top: 1px solid #ccc;
    line-height: 40px;
    min-height: 40px;
  }

  .module.widget-handle .menu {
    line-height: 40px;
  }

  .module.widget-handle .menu li {
    line-height: 40px;
  }

  .module.widget-handle i {
    line-height: 40px;
  }

  .module.widget-handle .title {
    display: inline-block;
    position: relative;
    bottom: 3px;
    margin-left: 8px;
  }

  .widget-handle .function {
    width: 100%;
    position: relative;
    opacity: 1;
    .translateY(0px);
    visibility: visible;
    margin-top: 0;
    display: none;
    box-shadow: none !important;
  }

  .toggle-widget-handle .function {
    display: block !important;
  }

  /*
  .widget-handle .cart .label {
    width: 22px;
    height: 22px;
    font-size: 12px;
    line-height: 22px;
    top: 18px;
  }
  */

  .mobile-toggle {
    border-left: 1px solid #ccc !important;
    border-bottom: none !important;
  }

  .mobile-toggle i {
    line-height: 53px !important;
  }

  .mobile-toggle.absolute-xs {
    position: absolute;
    top: 0;
  }

  nav.nav-centered .mobile-toggle {
    width: 100%;
  }

/*  nav.bg-dark .module.widget-handle {
    border-top: 1px solid #444;
  }*/

/*  nav.bg-dark .mobile-toggle {
    border-left: 1px solid #444 !important;
  }*/

/*  nav.bg-dark .nav-open .mobile-toggle {
    border-bottom: 1px solid #444;
  }*/

}

nav.outOfSight {
  .translateY(-@menu-height);
  .transition(0.3s);
  .title-bar{
    display: none;
  }
}

nav.scrolled {
  .translateY(0px);
}

nav.fixed, nav.absolute {
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  right: 0;
}

nav.absolute {
  position: absolute;
}

.simple {
  nav.absolute {
    position: inherit;
    background-color: @color-header-simple;
    > div {
      margin-top: 0px;
      img {
        margin-top: 8px;
      }
    }
  }
}

nav.fixed {
  position: fixed;
  visibility: hidden;
  opacity: 0;
}

nav.fixed .nav-utility {
  display: none;
}

nav.fixed.scrolled {
  visibility: visible;
  opacity: 1;
}

.has-offscreen-nav nav.fixed {
  opacity: 1;
  transform: none;
  visibility: visible;
}

​
nav.transparent {
  background: none;
}

nav.transparent .menu > li > a, nav.transparent .module.widget-handle i, nav.transparent .nav-utility {
  color: @color-white;
}
nav.transparent .menu > li > a:hover {
  color: @color-footer-bg !important;
}

nav.transparent .nav-utility, nav.transparent .nav-bar {
  // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

nav.transparent .module.widget-handle {
  // border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
}

nav.transparent .menu > li > a, nav.transparent .module.widget-handle i {
  opacity: 1;
}

nav.transparent .has-dropdown:after {
  color: @color-white;
}

nav.transparent .logo-dark {
  display: inline;
}

nav.transparent .logo-light {
  display: inline;
}

@media all and (max-width: 990px) {
  nav.transparent .nav-open {
    background: @color-white;
  }

  nav.transparent .nav-open .menu > li > a, nav.transparent .nav-open .module.widget-handle i, nav.transparent .nav-open .nav-utility {
    color: @bg-dark;
  }

  nav.transparent .nav-open .logo-dark {
    display: inline;
  }

  nav.transparent .nav-open .logo-light {
    display: none;
  }

  nav.transparent .nav-open .has-dropdown:after {
    color: @bg-dark;
  }

  .menu > li:last-child > ul ul {
    right: 0;
  }
}

nav.transparent.fixed {
  background: @color-white;
}

nav.transparent.fixed .logo-light {
  display: none;
}

nav.transparent.fixed .logo-dark {
  display: inline;
}

nav.transparent.fixed .menu > li > a, nav.transparent.fixed .module.widget-handle i {
  color: @bg-dark;
}

nav.transparent.fixed .menu > .has-dropdown:after {
  color: @color-white;
  color: @bg-dark;
}

nav.transparent.fixed .module.widget-handle {
  border-left: 1px solid #ccc;
}

nav.transparent.fixed .menu > li > a, nav.transparent.fixed .module.widget-handle i {
  //opacity: .5;
}

/*nav.bg-dark .menu > li > a, nav.bg-dark .module.widget-handle i, nav.bg-dark .nav-utility {
  color: @color-white;
  opacity: .7;
}*/

/*nav.bg-dark .module.widget-handle {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}*/

/*nav.bg-dark .nav-utility, nav.bg-dark .nav-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}*/

/*
nav.transparent.fixed.bg-dark {
  background: @bg-dark;
}
*/

/*nav.transparent.fixed.bg-dark .menu > li > a, nav.transparent.fixed.bg-dark .module.widget-handle i {
  color: @color-white;
}*/

.nav-bar{
  .logo-sticky{
    display: none;
    position: relative;
    top: 15px;
    // max-height: 25px;
  }
}

nav.outOfSight.scrolled {
  .title-bar .logo {
    display: none;
  }
  .nav-bar{
    background-color: none;
    .logo-sticky{
      display: block;

    }
  }
}

ul.menu > li.active{
  >a{
    color: @color-footer-bg !important;
  }
}

@media (max-width: 768px){
  ul.menu li span{
    color: @color-dark-gray !important;
  }
}

.fixed .menu li a:hover, .fixed .menu li span:hover {
  color: @color-footer-bg !important;
}



.product-single .image-slider .label {
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  z-index: 3;
  top: 16px;
  right: 16px;
}

/*.product-single .price {
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  display: inline-block;
  margin-right: 32px;
}*/

/*.product-single .old-price {
  text-decoration: line-through;
  opacity: .7;
}*/

/*.product-single .price:not(.old-price) {
  color: @color-primary;
}*/

/*.add-to-cart input[type="text"] {
  width: 100px;
  text-align: center;
  padding: 0;
  margin: 0;
}*/

/*
.add-to-cart input[type="submit"] {
  width: 160px;
  margin: 0;
}
*/

th, td {
  line-height: 24px !important;
}

/*.ratings {
  margin-bottom: 24px;
}*/

/*
.ratings li {
  display: inline-block;
  background: @bg-secondary;
  padding: 24px;
  margin-bottom: 16px;
}

.star-rating li {
  padding: 0;
  color: #000;
}

.star-rating {
  margin: 0;
  display: inline-block;
  margin-right: 24px;
}

.ratings .user span {
  display: inline-block;
  margin-right: 8px;
}

.ratings .user .date {
  opacity: .7;
}

.ratings .user {
  margin-bottom: 8px;
}

.ratings-form input[type="text"] {
  width: 50%;
  float: left;
}

.ratings-form input[type="text"]:first-child {
  width: 48%;
  margin-right: 2%;
}

.ratings-form input[type="submit"] {
  width: 200px;
}
*/

.product-item {
  padding-bottom: 32px;
  @media all and (min-width: 768px){
    padding-bottom: 64px;
  }

  .img-container{
    display: block;
    img.product-thumb {
      width: auto;
      height: auto;
      .transition(0.3s);
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
    @media all and (min-width: 768px){
      height: 280px;

      img.product-thumb{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
      }
    }
    @media all and (max-width: 767px){
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
  h3{
    margin-top: 50px;
    &:before{
      content: '';
      display: block;
      margin: auto;
      width: 50%;
      height: 3px;
      background-color: @color-gold;
      margin-bottom: 16px;
    }
  }
}

.col-sm-6 .product-thumb {
  max-width: 320px;
}

.product-thumb:hover {
  transform: scale3d(.95, .95, .95);
  -webkit-transform: scale3d(.95, .95, .95);
}

/*
.cart .product-thumb {
  max-width: 10000px;
  max-height: 100px;
}

.cart .product-thumb:hover {
  transform: none;
}

.cart td, .cart span, .cart th {
  vertical-align: middle !important;
}

.cart span {
  display: inline-block;
}

.cart .remove-item {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  border: 1px solid #c64444;
  color: #c64444;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  .transition(0.3s);
}

.cart .remove-item:hover {
  color: #fff;
  background: #c64444;
}
*/

.progress-bars {
  margin-bottom: 24px;
}

.progress {
  height: auto;
  box-shadow: none;
}

.progress-bar {
  background: @color-primary;
  box-shadow: none !important;
}

.bg-primary .progress-bar {
  background: lighten(@color-primary, 8%);
}

.progress-1 {
  height: 32px;
  margin-bottom: 8px;
  border-radius: 0;
  border: none;
}

.progress-1:last-child {
  margin-bottom: 0;
}

.progress-1 .progress-bar {
  text-align: right;
  padding-right: 12px;
  line-height: 32px;
  border-radius: 0;
}

.progress-1 .progress-bar span {
  .h6;
  font-weight: bold;
}

.progress-2 {
  height: auto;
  background: none;
  text-align: center;
  margin-bottom: 8px;
}

.progress-2 span {
  .h6;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 8px;
}

.progress-2 .bar-holder {
  height: 16px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid @color-primary;
}

.bg-primary .progress-2 .bar-holder {
  border-color: lighten(@color-primary, 12%);
}

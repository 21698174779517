footer.bg-dark a {
  color: @color-white;
}

footer li a {
  color: @color-white;
}

footer li a:hover {
  text-decoration: underline;
}

.footer-1 .logo {
  max-height: 30px;
}

.footer-1 .sub {
  font-size: 14px;
  color: @color-white;
}

.social-list {
  margin: 0;
}

.image-bg .social-list a {
  color: @color-white;
}

.bg-dark .social-list a, .image-bg .social-list a {
  color: @color-white;
  opacity: .5;
}

.bg-dark .social-list a:hover {
  opacity: 1;
}

.image-bg .social-list a {
  opacity: 1;
}

.bg-light .social-list a {
  color: #222;
}

.back-to-top {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.back-to-top:hover {
  opacity: 1;
}

.bg-dark .back-to-top {
  border-color: @color-white;
}

.bg-dark .back-to-top:active, .bg-dark .back-to-top:focus {
  color: @color-white;
}

.bg-dark .back-to-top:hover {
  background: none;
}

.filters {
  overflow: hidden;
  display: inline-block;
}

.filters li {
  float: left;
  margin-right: 32px;
  cursor: pointer;
  .btn;
  .btn-sm;
  height: 40px;
  line-height: 36px;
  &:after{
    display: none !important;
  }
  color: @color-primary !important;
  font-size: 12px !important;
  padding: 0 16px;
  border-color: rgba(255, 255, 255, 0);
  //opacity: .7;
  border-radius: 25px;
  .disable-select;
  display: inline-block;
  &:hover{
    border-color: @color-primary !important;
  }

}

.filters li.active {
  border-color: @color-primary !important;
  color: @color-primary !important;
  opacity: 1;
}

.filters li:last-child {
  margin-right: 0;
}

.filters.floating {
  position: absolute;
  z-index: 10;
  top: 16px;
  text-align: center;
  left: 50%;
  .translateX(-50%);
  background: rgba(255, 255, 255, 0.98);
  .transition(0.3s);
  border-radius: 200px;
}

.filters.floating li {
  margin-bottom: 0
}

.bg-dark .filters.floating {
  .bg-dark;
}

.masonry-item.project {
  .transition(0.3s);
  opacity: 1;
  padding: 0;
}

.container .project {
  padding: 0 5px;
}

.project.inactive {
  opacity: .1 !important;
  pointer-events: none !important;
}

.project .image-tile {
  margin: 0;
}

.project.inactive:hover .title, .project.inactive .image-tile:hover:before {
  display: none;
}

@media all and (max-width: 868px) {
  .filters.floating {
    width: 90vw;
  }

  .filters.floating li {
    float: none;
  }
}

@media all and (max-width: 767px) {
  .filters.floating {
    border-radius: 0;
    padding: 8px;
  }

  .filters.floating li {
    width: 50%;
    float: left;
    margin: 0;
    padding: 0px;
  }
}

section + .portfolio-pullup {
  padding-top: 0;
  margin-top: -128px;
  position: relative;
  z-index: 20;
}

.portfolio-pullup .project {
  margin-bottom: 0;
}

.portfolio-pullup .inner-title:hover {
  opacity: 1;
}

@charset"UTF-8";

.mb_YTPlayer {
  animation-timing-function: linear;
  backface-visibility: hidden;
  box-sizing: border-box;
  display:block;
  perspective: 1000;
  transform-style: preserve-3d;
  transform:translate3d(0,0,0);
  transform:translateZ(0);
  &:focus {
    outline: 0;
  }
  .mb_YTPPlaypause {
    img {
      cursor:pointer;
    }
  }
}
.mbYTP_wrapper {
  iframe {
    max-width: 4000px !important;
  }
}
.inline_YTPlayer {
  background: rgba(0,0,0,.5);
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0,0,0,.7);
  left:0;
  margin-bottom: 20px;
  overflow: hidden;
  position:relative;
  vertical-align:top;
  img {
    -moz-transform: none!important;
    -o-transform: none!important;
    -webkit-transform: none!important;
    border: none!important;
    margin:0!important;
    padding:0!important;
    transform: none!important;
  }
}
.mb_YTPBar {
  -moz-box-sizing:padding-box;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  -webkit-box-sizing:border-box;
  -webkit-transition: opacity 1s;
  background:#333333;
  bottom:0;
  color:white;
  display:block;
  font: 14px/16px sans-serif;
  height:10px;
  left:0;
  opacity:.1;
  padding:5px;
  position:fixed;
  text-align:left;
  transition: opacity 1s;
  width: 100%;
  z-index: 1000;
  .ytpicon {
    font-family: 'ytpregular';
    font-size: 20px;
  }
  .mb_YTPUrl.ytpicon {
    font-size: 30px;
  }
  &:hover {
    opacity:1;
    .buttonBar {
      background: rgba(0,0,0,0.4);
    }
  }
  .buttonBar {
    -moz-box-sizing: border-box;
    -moz-transition: all 1s;
    -o-box-sizing: border-box;
    -o-transition: all 1s;
    -webkit-box-sizing: border-box;
    -webkit-transition: all 1s;
    background:transparent;
    box-sizing: border-box;
    font:12px/14px Calibri;
    left:0;
    padding: 5px;
    position:absolute;
    top:-30px;
    transition: all 1s;
    width: 100%;
  }
  span {
    display:inline-block;
    font:16px/20px Calibri, sans-serif;
    height: 25px;
    position:relative;
    vertical-align: middle;
    width: 30px;
  }
  span.mb_YTPTime {
    width: 130px;
  }
  span.mb_YTPUrl {
    cursor: pointer;
    display:block;
    position: absolute;
    right:10px;
    top:6px;
    width: auto;
    img {
      width: 60px;
    }
    a {
      color:white;
    }
  }
  span.mb_OnlyYT {
    cursor: pointer;
    display:block;
    left:185px;
    position: absolute;
    right: auto;
    right:10px;
    top:6px;
    width: auto;
    img {
      width: 25px;
    }
  }
}
.mb_YTPBar.visible {
  opacity:1;
}
.mb_YTPPlaypause {
  cursor:pointer;
}
.mb_YTPMuteUnmute {
  cursor:pointer;
}
.mb_YTPProgress {
  background:#222222;
  bottom:0;
  height:10px;
  left:0;
  width: 100%;
}
.mb_YTPLoaded {
  background:#444444;
  height:10px;
  left:0;
  width:0;
}
.mb_YTPseekbar {
  background:#ffffff;
  bottom:0;
  box-shadow: rgba(82, 82, 82, 0.47) 1px 1px 3px;
  height:10px;
  left:0;
  width:0;
}
.YTPOverlay {
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  box-sizing: border-box;
}
//.YTPOverlay.raster {
//  background: url("../images/raster.png");
//}
//.YTPOverlay.raster.retina {
//  background: url("../images/raster@2x.png");
//}
//.YTPOverlay.raster-dot {
//  background: url("../images/raster_dot.png");
//}
//.YTPOverlay.raster-dot.retina {
//  background: url("../images/raster_dot@2x.png");
//}

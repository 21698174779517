.tabs {
  overflow: hidden;
}

.tabs li {
  display: inline-block;
  cursor: pointer;
}

.tabs.thirds {
  width: 100%;
}

.tabs.thirds li {
  width: 33.3333333%;
}

.tabs.thirds .tab-title {
  width: 100%;
}

.button-tabs .tabs {
  display: inline-block;
  overflow: hidden;
}

.button-tabs .tabs > li {
  margin-bottom: 8px;
  float: left;
}

.button-tabs .tabs > li:last-child {
  margin-right: 0;
}

.button-tabs .tab-title {
  padding: 13px;
  display: inline-block;
  text-align: center;
  min-width: 150px;
  background: #f5f5f5;
  .disable-select;
  .transition(0.3s);
}

.bg-secondary .button-tabs .tab-title {
  background: #eee;
}

.button-tabs .tab-title span {
  .h6;
  font-weight: bold;
}

.button-tabs .active .tab-title {
  background: @color-primary;
  color: @color-white;
}

.button-tabs .tab-title:hover {
  background: #eee;
}

.button-tabs .active .tab-title:hover {
  background: @color-primary;
}

.button-tabs.vertical {
  overflow: hidden;
}

.button-tabs.vertical > .tabs {
  max-width: 30%;
  float: left;
}

.button-tabs.vertical > .content {
  padding-left: 3%;
  max-width: 70%;
  float: right;
}

.button-tabs.vertical > .tabs li, .button-tabs.vertical .tab-title {
  width: 100%;
}

.button-tabs.vertical > .tabs li {
  margin-bottom: 10px;
}

.bg-dark .button-tabs .tab-title span {
  color: #222;
}

@media all and (max-width: 767px) {
  .button-tabs .tabs li {
    width: 49%;
    margin-bottom: 15px;
  }

  .button-tabs.vertical .tabs, .button-tabs.vertical .content {
    max-width: 100%;
    width: 100%;
    padding-left: 0;
  }
}

.tabbed-content .content > li {
  opacity: 0;
  visibility: hidden;
  .transition(0.3s);
  display: none;
}

.tabbed-content .content .active {
  opacity: 1;
  visibility: visible;
  display: block;
}

.text-tabs .tabs {
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
  overflow: visible;
}

.text-tabs.no-border .tabs {
  border-bottom: none;
}

.text-tabs .tab-title {
  .disable-select;
  .transition(0.3s);
  padding: 0 24px;
  opacity: 0.5;
  position: relative;
}

.text-tabs .tab-title span {
  .h6;
  font-weight: bold;
}

.text-tabs .tab-title:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: @color-primary;
  bottom: 10px;
  opacity: 0;
  .transition(0.3s);
  bottom: -17px;
  left: 0;
}

.text-tabs .active .tab-title {
  opacity: 1;
  color: @color-primary;
}

.bg-primary .text-tabs .tab-title:after {
  background: lighten(@color-primary, 30%);
}

.text-tabs .active .tab-title:after {
  opacity: 1;
}

.text-tabs .tab-title:hover {
  opacity: 1;
}

@media all and (max-width: 767px) {
  .text-tabs .tabs {
    padding-bottom: 0;
  }

  .text-tabs .tabs li {
    width: 49%;
    margin-bottom: 15px;
  }

  .text-tabs .tab-title:after {
    display: none;
  }

  .text-tabs:not(.text-center) .tabs li:first-child .tab-title {
    padding-left: 24px;
  }

}

.icon-tabs .tabs li {
  width: 23.5%;
  margin-bottom: 24px;
}

.icon-tabs .tab-title {
  text-align: center;
  .transition(0.3s);
  opacity: .5;
}

.icon-tabs .tab-title:hover {
  opacity: 1;
}

.icon-tabs .active .tab-title {
  color: @color-primary;
  opacity: 1;
}

.icon-tabs .active .tab-title i {
  color: @color-primary;
}

.image-bg .icon-tabs .active .tab-title i {
  color: @color-white;
}

.icon-tabs .tab-title i {
  display: block;
  margin-bottom: 16px;
}

.icon-tabs .tab-title span {
  .h6;
  font-weight: bold;
}

@media all and (max-width: 767px) {
  .icon-tabs .tabs li {
    margin-bottom: 16px;
    margin-right: 0;
    width: 49%;
  }

  .icon-tabs .tab-title i {
    font-size: 32px;
  }
}

.btn {
  font-family: @ff-interstate, "Helvetica Neue", Helvetica, Arial, sans-serif;
  border : 3px solid transparent !important;
  padding: 0 24px;
  height: 46px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 5px;
  border-radius: 0;
  color: @color-primary;
  text-align: center;
  .transition(0.3s);
  margin-right: 8px;
  margin-bottom: 24px;
  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: @color-gold;
  }
  &:hover{
    background: transparent !important;
    color: @color-white !important;
    border-color: @color-white !important;
    &:after{
      display: none;
    }
  }
  &:last-child, &:last-of-type{
    margin-right: 0;
  }
}


.btn-icon {
  width: 40px;
  height: 40px;
  font-size: 20px;
  min-width: 0;
  padding: 0;
  line-height: 38px;
}

.btn-lg {
  height: 50px;
  line-height: 44px;
}

.btn-icon.btn-lg {
  width: 50px;
  height: 50px;
  line-height: 49px;
  font-size: 24px;
  min-width: 0;
}

.btn-icon.btn-sm {
  width: 30px;
  height: 30px;
  line-height: 29px;
  font-size: 13px;
  min-width: 0;
  padding: 0 0 0 1px !important;
}

.btn-sm {
  height: 30px;
  font-size: 11px;
  line-height: 27px;
  min-width: 0;
}

.btn-filled {
  background: @color-primary;
  color: @color-white;
}

.btn-white, .image-bg .btn, .image-bg .btn:visited {
  color: @color-white;
  border-color: @color-white;
}

.btn-white:hover, .image-bg .btn:hover, .image-bg .btn:visited:hover {
  background: @color-white;
  color: #222;
}

.image-bg .btn.btn-filled, .image-bg .btn-filled:visited {
  border-color: @color-primary;
}

.image-bg .btn-filled:hover {
  border-color: @color-white;
}

.btn-rounded {
  border-radius: 25px;
}

body.btn-rounded .btn {
  border-radius: 25px !important;
}

.bg-light .btn {
  border-color: @color-dark-gray;
  color: @color-dark-gray;
}

.bg-light .btn:visited, .bg-light .btn:visited:hover {
  color: @color-dark-gray;
}

.bg-light .btn-filled, .bg-light .btn-filled:visited {
  color: @color-white;
}

.btn:visited {
  color: @color-primary;
}

.btn-white:visited, .btn:visited:hover {
  color: @color-white;
}

.btn-white:visited:hover {
  color: #222;
}

.btn-filled:visited {
  color: @color-white;
}

.btn.bg-dark {
  color: @color-white;
  border-color: @bg-dark;
}

.btn.bg-dark:hover {
  background: lighten(@bg-dark, 10%);
}

.bg-primary .btn {
  color: @color-white;
  border-color: @color-white;
}

.bg-primary .btn:hover {
  background: @color-white;
  color: @color-primary;
}

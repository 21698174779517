#cookie-banner {
    background-color: @color-white;
    color: @color-dark-gray;
    width: 100%;
    z-index: 500;
    position: fixed;
    bottom: 0;
    text-align: center;
    letter-spacing: 2px;
    padding: 1rem 0;

    div {
        display: inline-block;
    }

    a {
        color: @color-gold;
        text-decoration: underline;
    }

    .button {
        background-color: transparent;
        padding: 0.5rem 0.5rem;
        border-bottom: 2px solid #a18b5f;
        display: inline-block;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 2px solid @color-gold;

        letter-spacing: 3px;
        text-align: center;
        margin: 0 0 0 2rem;
    }
}
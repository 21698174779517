body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 24px;
  font-weight: 200;
  font-family: @ff-open-sans;
  color: #3D3D3D;
  overflow-x: hidden;
}

body.boxed-layout {
  background: #eee;
}

body.boxed-layout .main-container {
  background: #fff;
}

body.boxed-layout, .boxed-layout .nav-container, .boxed-layout .main-container, .boxed-layout nav {
  max-width: 1366px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

ul {
  list-style: none;
}

ul.bullets {
  list-style: inside;
}

.main-container {
  clear: both;
}

hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0 0 24px 0;
  width: 100%;
}

hr.short-thick {
  max-width: 50px;
  border-top: 5px solid #ccc;
  opacity: 1 !important;
}

.image-bg hr {
  border-color: #fff;
  opacity: .6;
}

.image-bg.bg-light hr {
  border-color: #ccc;
  opacity: 1;
}

.bg-dark hr {
  border-color: #555;
}

.inline-block {
  display: inline-block;
}

.list-inline {
  margin-left: 0;
}

.list-inline > li {
  padding: 0 8px;
}

.list-inline > li:last-child {
  padding-right: 0;
}

.list-inline > li:first-child {
  padding-left: 0;
}

.bg-primary .list-inline i {
  color: lighten(@color-primary, 20%);
}

.overflow-hidden {
  overflow: hidden;
}

.display-block {
  display: block;
}

.show-grid {
  border: 1px dashed rgba(255, 255, 255, 0);
  padding: 8px;
  .transition(0.2s);
  cursor: default;
}

.show-grid:hover {
  border-color: #222;
}

.right {
  right: 0;
}

.relative {
  position: relative;
  z-index: 2;
}

.clearboth {
  clear: both;
}

.spread-children * {
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
}

.spread-children-large * {
  display: inline-block;
  margin-left: 24px;
  margin-right: 24px;
}

.container {
  position: relative;
}

.vnu {
  display: inline;
}

.row-gapless {
  > div[class*='col-'] {
    padding: 0;
  }
}

@media all and (max-width: 1100px) {
  .col-md-push-1 {
    left: 0;
  }
}

@media all and (max-width: 768px) {
  .pull-left-sm {
    float: left !important;
  }

  .overflow-hidden-xs {
    overflow: hidden;
  }
}

@media all and (max-width: 767px) {
  .spread-children * {
    margin-left: 6px;
    margin-right: 6px;
  }
}

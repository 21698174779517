.instafeed.grid-gallery {
  overflow: hidden;
}

.instafeed.grid-gallery li {
  width: 25%;
  display: inline-block;
  float: left;
  padding: 0 4px 0 0;
  .transition(0.3s);
  opacity: 1;
}

.instafeed.grid-gallery li:hover {
  opacity: .9;
}

.instafeed.grid-gallery.gapless li {
  padding: 0;
  width: 16.66667%;
}

.instafeed.grid-gallery.gapless li img {
  width: 100%;
}

.instafeed li img {
  height: auto;
}

@media all and (max-width: 990px) {
  .instafeed.grid-gallery li {
    width: 33.333333%;
  }
}

.lightbox-grid, .lightbox-grid ul, .lightbox-grid li {
  position: relative;
  overflow: hidden;
}

.lightbox-grid li {
  width: 25%;
  float: left;
  border: 8px solid rgba(0, 0, 0, 0);
  .transition(0.3s);
  opacity: 1;
}

.lightbox-grid li:hover {
  opacity: .9;
}

.square-thumbs li {
  height: 285px;
}

.third-thumbs li {
  width: 33.33333vw;
  height: 33vw;
  border: none;
}

@media all and (max-width: 1190px) {
  .square-thumbs li {
    height: 235px;
  }
}

@media all and (max-width: 990px) {
  .lightbox-grid li {
    width: 33.33333%
  }

  .square-thumbs li {
    height: 240px;
  }
}

@media all and (max-width: 767px) {
  .lightbox-grid li {
    width: 50%
  }

  .third-thumbs li {
    height: 50vw;
  }

  .square-thumbs li {
    height: 185px;
  }
}

.lb-outerContainer {
  background: none;
  border-radius: 0;
}

.lightbox .lb-image {
  border-radius: 0;
}

.lb-data .lb-close, .lb-nav a.lb-next, .lb-nav a.lb-prev {
  background: none;
}

.lb-data .lb-close:before, .lb-nav a.lb-next:before, .lb-nav a.lb-prev:before {
  content: "\e646";
  font-family: 'themify';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  color: #fff;
}

.lb-nav a.lb-next:before, .lb-nav a.lb-prev:before {
  position: absolute;
  top: 50%;
  font-size: 32px;
  margin-top: -16px;
}

.lb-nav a.lb-next:before {
  content: "\e649";
  right: 16px;
}

.lb-nav a.lb-prev:before {
  content: "\e64a";
  left: 16px;
}

.flickr-feed li {
  width: 25%;
  padding: 8px;
}

.flickr-feed li.masonry-item {
  margin-bottom: 0;
}

.flickr-feed li img {
  min-width: 100%;
}

@media all and (max-width: 990px) {
  .flickr-feed li {
    width: 33.33333%
  }
}

@media all and (max-width: 767px) {
  .flickr-feed li {
    padding: 4px;
  }
}

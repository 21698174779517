
@fonts-folder: "../../fonts";
@img-folder: "../../img";
@video-folder: "../../video";

a.not-clickable {
    text-decoration: none;
    cursor: default;
    //pointer-events: none;

    &:active, &:focus, &:hover {
        text-decoration: none;
        cursor: default;
        //pointer-events: none;
        color: inherit;
    }
}
.alert {
  margin-bottom: 24px;
  background: none;
  border-radius: 0;
  font-weight: 600;
  padding: 12px 16px;
}

.alert .close {
  opacity: 1;
  font-size: 24px;
  top: -2px;
  right: -4px;
  .transition(0.3s);
}

.alert .close span {
  font-weight: 400;
}

.alert-warning {
  border: 1px solid #d88519;
  color: #d88519;
}

.alert-success {
  border: 1px solid #60b963;
  color: #60b963;
}

.alert-danger {
  border: 1px solid #c64444;
  color: #c64444;
}

@media all and (max-width: 767px) {
  .text-center-xs {
    text-align: center !important;
  }
}

@heading-font: @ff-interstate;
.bold {
  font-weight: 600 !important;
}

.thin {
  font-weight: 100 !important;
  @media all and (max-width: 767px) {
    font-weight: 300 !important;
  }
}

.uppercase{
  text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6, p, ul, ol, pre, table, blockquote, input, button, select, textarea {
  margin-bottom: 24px;
  margin-top: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: @heading-font;
  font-weight: 400;
  color: @color-primary;
  &.gold{
    color: @color-primary !important;
  }
  &.line{
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    &:after, &:before {
      content: '';
      display: block;
      width: 10%;
      height: 3px;
      background-color: @color-gold;
      position: absolute;
      top: 50%;
    }
    &:after{
      right: 0;
      transform: translateX(124%);
      -webkit-transform: translateX(124%);
    }
    &:before {
      left: 0;
      transform: translateX(-124%);
      -webkit-transform: translateX(-124%);
    }
  }
}

h1, .h1 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0px;
  //margin-right: -8px;
  @media all and (max-width: 990px) {

  }
  @media all and (max-width: 767px) {

  }
  &.large {
    font-size: 42px !important;
    line-height: 56px !important;
    /*font-weight: 100;*/
    @media all and (max-width: 990px) {
      font-size: 24px;
      line-height: 28px;
    }
    @media all and (max-width: 767px) {
      font-size: 32px;
      line-height: 40px;
      font-weight: 300;
    }
  }
}

h2, .h2 {
  font-size: 40px;
  line-height: 46px;
  letter-spacing: 0px;
  font-weight: 200;
  //margin-right: -8px;
  @media all and (max-width: 990px) {

  }
  @media all and (max-width: 767px) {

  }
  &.meie{
    font-family: @ff-meie;
    letter-spacing: 1px;
    font-size: 26px;

  }
}

h3, .h3 {
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0px;
  //margin-right: -8px;
  font-weight: 200;
  @media all and (max-width: 990px) {

  }
  @media all and (max-width: 767px) {

  }
}

h4, .h4 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  @media all and (max-width: 990px) {

}
  @media all and (max-width: 767px) {

  }
}

h5, .h5 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  @media all and (max-width: 990px) {

  }
  @media all and (max-width: 767px) {

  }
}

h6, .h6 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  @media all and (max-width: 990px) {

  }
  @media all and (max-width: 767px) {

  }
}

.uppercase {
  //font-weight: 400;
  text-transform: uppercase;
}

p, span {
  font-weight: 200;
}

p{
  @media all and (max-width: 767px) {
    font-size: 16px;
  }
  .lead {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    @media all and (max-width: 767px) {
      font-size: 13px;
      line-height: 24px;
    }
  }
}

.sub {
  font-size: 12px;
}

a {
  font-weight: 400;
  color: @color-primary;
  .transition(0.3s);
  //cursor: default;
  text-decoration: none;
  outline: none;

  &:hover, &:focus {
    color: @color-dark-gray;
    text-decoration: none;
  }

  &:visited, &:focus, &:active, &:hover{
    text-decoration: none;
    outline: none;
  }
}

.image-bg, .bg-primary{
  a{
    color: #fff;
    &:hover{
      color: #fff;
      opacity: .9;
    }
  }
}

.bg-light {
  a {
    color: @color-primary;
    &:hover {
      color: @color-primary;
      opacity: 1;
    }
  }
}
.label {
  text-transform: uppercase;
  //.bold-h6;
  .h6;
  font-weight: bold;
  letter-spacing: 1px;
  background: @color-primary;
  border-radius: 0;
  padding: 6px 12px;
  font-size: 10px;
  vertical-align: middle;
}

blockquote {
  overflow: hidden;
  font-family: "Merriweather", "Georgia", Times New Roman, Times, serif;
  font-size: 20px;
  line-height: 40px;
  font-style: italic;
  background: #f5f5f5;
  padding: 32px;
  color: #777;
  font-weight: 300;
}

.bg-secondary {
  blockquote {
    background: #fff;
    border-color: @color-primary;
  }
}

blockquote {
  .author {
    font-size: 12px;
    display: block;
    float: right;
    margin-top: 16px;
  }

  @media all and (max-width: 767px) {
    font-size: 16px;
    line-height: 32px;
  }
}

.number {
  font-family: @ff-open-sans;
}

.columns-2 {
  column-count: 2;
  -webkit-column-count: 2;
  @media all and (max-width: 767px) {
    column-count: 1;
    -webkit-column-count: 1;
  }
}

br{
  @media all and (max-width: 767px) {
    display: none;
  }
}

.text-left {
  text-align: left !important;

  @media all and (max-width: 767px) {
    text-align: left !important;
  }

}



// lists

ul[data-bullet] li {
  line-height: 32px;
}

ul[data-bullet] li i:first-child {
  margin-right: 16px;
  .scale(1.5);
  display: inline-block;
}

ul.lead li {
  font-size: 16px;
  line-height: 40px;
}

// recipes
.ingredients-wrapper .ezrichtext-field {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    &:extend(.h4);
  }
}

.procedure-wrapper .ezrichtext-field {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    &:extend(.h3);
    text-transform: uppercase;
  }
}

/* josefin-sans-100 - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 100;
  src: local(''),
       url('/var/new/josefin-sans-v25-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/var/new/josefin-sans-v25-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-100italic - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 100;
  src: local(''),
       url('/var/new/josefin-sans-v25-latin-100italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/var/new/josefin-sans-v25-latin-100italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-200 - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('/var/new/josefin-sans-v25-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/var/new/josefin-sans-v25-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-200italic - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 200;
  src: local(''),
       url('/var/new/josefin-sans-v25-latin-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/var/new/josefin-sans-v25-latin-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-regular - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/var/new/josefin-sans-v25-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/var/new/josefin-sans-v25-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-700 - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('/var/new/josefin-sans-v25-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/var/new/josefin-sans-v25-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-italic - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('/var/new/josefin-sans-v25-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/var/new/josefin-sans-v25-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-700italic - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('/var/new/josefin-sans-v25-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/var/new/josefin-sans-v25-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@ff-open-sans: "Josefin Sans", sans-serif;
@ff-meie:"Josefin Sans", cursive;
@ff-interstate: "Josefin Sans", Arial, sans-serif;
.pagination {
  margin: 0;
}

.pagination li {
  .number;
}

.pagination li a {
  width: 32px;
  height: 32px;
  padding: 0;
  text-align: center;
  padding-top: 6px;
  margin: 0 4px;
  color: @bg-dark;
  font-weight: 400;
}

.pagination li a:hover, .pagination li.active a, .pagination li.active:hover a {
  background: @color-primary;
  color: @color-white;
  border-color: @color-primary;
}

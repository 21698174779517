.image-slider {
  position: relative;

  .slides {
    position: relative;
    overflow: hidden;
    li {
      position: relative;
      & > img {
        width: 100%;
      }
    }
  }

  &.height-70{
    .slides {
      li {
        height: 70vh;
        min-height: 600px;
        @media all and(max-width: 767px){
          height: auto;
          min-height: initial;
          padding: 80px 0;
        }
      }
    }
    .v-align-transform {
      @media all and(max-width: 767px) {
        top: 0;
        transform: none;
        -webkit-transform: none;
      }
    }
  }

  .container.baseline{
    @media all and(min-width: 768px) {
      position: relative;
      transform: translate3d(0, -100%, 0);
      -webkit-transform: translate3d(0, -100%, 0);
      top: 70%;    
    }

    @media all and(max-width: 768px) {
      display: none; 
    }
    
    z-index: 2;
    .header, .caption{
      //background-color: rgba(0, 0, 0, 0.7);
      width: 450px;
      overflow: hidden;
      margin: auto;
      text-align: center;
      padding: 30px 15px 40px 15px;
      @media all and (max-width: 767px) {
        width: 90%;
      }
    }

    h1 {
      font-size: 14px;
      margin-bottom: 18px;
    }

    img {
      width: 20px;
    }
  }

  .container.centered{
    @media all and(min-width: 768px) {
      position: relative;
      transform: translate3d(0, -100%, 0);
      -webkit-transform: translate3d(0, -100%, 0);
      top: 60%;    
      width: 90%;
    }

    @media all and(max-width: 768px) {
      top: 40px; 
    }
    
    z-index: 2;
    .header, .caption{
      width: 100%;
      overflow: hidden;
      margin: auto;
      text-align: center;
      padding: 30px 15px 40px 15px;
      @media all and (max-width: 767px) {
        width: 90%;
        margin-top: 48px;
      }
    }

    h1 {
      letter-spacing: 6px;
      span {
        @media all and (max-width: 767px) {
          font-weight: 400;
          letter-spacing: 10px;
          font-size: 20px;
        }
      }
    }

    h2{
      text-transform: uppercase;
      font-size: 100px;
      font-weight: 100;
      letter-spacing: 12px;

      @media all and(max-width: 768px) {
        font-size: 36px; 
        line-height: 40px;
        letter-spacing: 8px;
        word-break: break-all;
      }
    }
  }
}
.row .image-slider {
  margin-bottom: 24px;
}

.flex-direction-nav {
  a{
    opacity: 1;
    text-shadow: none;
    color: rgba(0, 0, 0, 0);
    width: 24px;
    height: 24px;
    margin: -12px 0 0;

    &.flex-next {
      right: 16px;
    }

    &.flex-next, &.flex-prev{
      &:before{
        content: "\f054";
        font-family: 'Font Awesome 5 Free';
        font-size: 24px;
        font-weight: 900;
        color: @color-white;
      }
    }

    &.flex-prev {
      left: 16px;
      &:before{
        content: "\f053";
      }
    }

  }

  @media all and (max-width: 768px) {
    display: none;
  }
}


.flex-control-nav {

  li {
    margin: 0 10px;
    overflow: hidden;

    a {
      background: @color-dark-gray;
      width: 8px;
      height: 8px;
    }
  }
}

.flex-control-paging {
  li {
    a {
      &.flex-active {
        background: @color-primary;
      }
    }
  }
}


.controls-inside {

  .flex-control-nav {
    bottom: 24px;
    z-index: 10;
    text-align: center;

    li {
      a {
        background: @color-white;

        &.flex-active {
          background: @color-primary;
        }
      }
    }
  }
}

.image-bg{
  .flex-control-nav{
    li {
      a {
        border-color: #fff;
        &.flex-active {
          background: #fff;
        }
      }
    }
  }
}

.slider-thumb-controls{
  .flex-control-thumbs {
    margin: 0;
    @media all and (max-width: 767px) {
      display: none;
    }
  }
  .flex-direction-nav {
    display: none;
    @media all and (max-width: 767px) {
      display: block;
    }
  }
}


.logo-carousel{
  li {
    text-align: center;
    .transition(0.3s);
    opacity: .5;
    &:hover {
      opacity: 1;
    }
  }
  img {
    max-height: 60px;
  }
}

@media all and (max-width: 767px) {
  .logo-carousel .slides li {
    width: 100%;
  }
}

.text-slider .flex-direction-nav li a:before {
  color: #222;
}

.image-bg .text-slider .flex-direction-nav li a:before {
  color: #fff;
  .transition(0.3s);
  opacity: .5;
}

.image-bg .text-slider .flex-direction-nav li a:hover:before {
  opacity: 1;
}

.text-slider .flex-direction-nav a.flex-prev {
  left: -60px;
}

.text-slider .flex-direction-nav a.flex-next {
  right: -60px;
}

.kenburns .slides li:nth-of-type(odd) .background-image-holder {
  animation: kenBurnsEast 25s linear infinite alternate;
  -webkit-animation: kenBurnsEast 25s linear infinite alternate;
}

.kenburns .slides li:nth-of-type(even) .background-image-holder {
  animation: kenBurnsWest 25s linear infinite alternate;
  -webkit-animation: kenBurnsWest 25s linear infinite alternate;
}


@keyframes kenBurnsEast {
  from {
    transform: scale(1) translateX(0px);
    -webkit-transform: scale(1) translateX(0px);
  }
  to {
    transform: scale(1.2) translateX(100px);
    -webkit-transform: scale(1.2) translateX(100px);
  }
}

@-webkit-keyframes kenBurnsEast {
  from {
    transform: scale(1) translateX(0px);
    -webkit-transform: scale(1) translateX(0px);
  }
  to {
    transform: scale(1.2) translateX(100px);
    -webkit-transform: scale(1.2) translateX(100px);
  }
}

@keyframes kenBurnsWest {
  from {
    transform: scale(1) translateX(0px);
    -webkit-transform: scale(1) translateX(0px);
  }
  to {
    transform: scale(1.2) translateX(-100px);
    -webkit-transform: scale(1.2) translateX(-100px);
  }
}

@-webkit-keyframes kenBurnsWest {
  from {
    transform: scale(1) translateX(0px);
    -webkit-transform: scale(1) translateX(0px);
  }
  to {
    transform: scale(1.2) translateX(-100px);
    -webkit-transform: scale(1.2) translateX(-100px);
  }
}

@media all and(max-width: 767px) {
  .kenburns .slides li .background-image-holder {
    animation: none !important;
    -webkit-animation: none !important;
  }
}

.scroll-downs {
  position: absolute;
  z-index: 3;
  bottom: 46px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  margin: auto;
  width :24px;
  height: 44px;
  &:after{
    color: @color-white;
    font-size: 11px;
    content: "\f078";
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.mousey {
  width: 5px;
  padding: 12px 8px;
  height: 16px;
  border: 2px solid @color-primary;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: @color-white;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}

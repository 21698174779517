section, footer {
  padding: 96px 0;
  position: relative;
  overflow: hidden;
}

footer {
  padding: 72px 0;
  background-color: @color-footer-bg;
  color: @color-white;
}

.fullscreen {
  height: 100vh;
}

.bg-dark, nav.bg-dark, footer.bg-dark, .bg-primary {
  .heading-colors(@color-white);
  .text-colors(#fefefe);
}


@media all and (max-width: 767px) {
  section {
    padding: 80px 0;
  }
}

.overlay:before {
  .overlay-params(.5, @color-black);
}

.video.overlay:before {
  .overlay-params(.1, @color-black);
}

.overlay-heavy:before {
  opacity: .6;
}

.bg-light.overlay:before {
  .overlay-params(.75, @color-white);
}
.video.bg-light.overlay:before {
  .overlay-params(.1, @color-white);
}

.image-bg {
  .heading-colors(@color-white);
  .text-colors(@color-white);
}

.image-bg .container, .image-bg div[class*='col-'] {
  position: relative;
  z-index: 3;
}

.container.image-bg .row {
  position: relative;
  z-index: 3;
}

.background-image-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: @bg-dark;
  background-size: cover !important;
  background-position: 50% 50% !important;
  .transition(0.3s);
  opacity: 0;
}

.background-image-holder img {
  display: none;
}

.background-multiply .background-image-holder {
  background-color: @color-primary !important;
  background-blend-mode: multiply;
}

.background-image-holder.fadeIn {
  opacity: 1;
}

.parallax > .background-image-holder, .parallax .slides li > .background-image-holder {
  height: 100vh;
  top: -50vh;
  transition: all 0s ease !important;
  transition: opacity 0.3s ease !important;
  -webkit-transform-style: preserve-3d;
}

.parallax:first-child .slides li > .background-image-holder, .parallax:first-child .background-image-holder {
  top: 0;
}

.main-container > a:first-child + .parallax .background-image-holder {
  top: 0;
}

@media all and (max-width: 767px) {
  .parallax > .background-image-holder, .parallax .slides li > .background-image-holder {
    top: 0 !important;
    transform: none !important;
    -webkit-transform: none !important;
  }
}

.phases{
  .phase{
    p{
      text-align: center;
      padding: 0 20%;
    }
    @media all and (min-width: 768px){

      &:nth-child(odd){
        padding-right:  2px;
      }
      &:nth-child(even){
        padding-left:  2px;
      }
    }

  }
  border-bottom: @color-light-gray solid 2px;
}

.feature.video {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.video-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 56.25%;

  iframe {
    height: calc(100% + 250px);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    margin-top: -125px;
  }
}
.video-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.cave-intro {
  h2 {
    color: @color-white;    
    padding-bottom: 10px;
    font-family: "Josefin Sans", sans-serif;
    line-height: normal;
    font-size: 38px;
    span {
      font-weight: 200;
    }
  }

  p {
    color: @color-white;
    font-size: 18px;
    font-weight: 200;
  }
}

.cave-icons {
  .feature {    
    .icon-container{
      height: 200px;
      @media all and (min-width: 768px){
        img{
          height: 30%;
        }
      }
    }
  }

  .text-intro {
    h2 {
      color: @color-white;
      font-size: 38px;
      line-height: normal;
      letter-spacing: 0
    }
    .p {
      color: @color-white;
      font-size: 24px;
      font-weight: 200;
      line-height: 28px;
      padding: 0 48px;
    }
  }
  .feature {
    background-color: @color-black;
    opacity: 0.8;
    width: 99%;
    p {
      color: @color-white;
      font-size: 16px;
      font-weight: 200;
    }
  }
}

.bg-black {
  background-color: @color-black;
  color: @color-white;
  h2 {
    color: @color-primary;
  }
  .p {
    color: @color-white;
    font-size: 18px;
    font-weight: 200;
    transform: translateY(50%);
    @media (max-width: 768px){
      transform: none;
    }
  }
}

.cave-step {
  background-color: @color-black;
  color: @color-white !important;
  h2 {
    color: @color-primary;
    text-transform: uppercase;
    font-size: 20px;
    span {
      font-weight: 400;
    }
  }
  h3 {
    font-size: 28px;
    color: white;
    font-family: "Josefin Sans", sans-serif;
    line-height: normal;
    span {
      font-weight: 200;
    }
  }
  .p {
    font-size: 18px;
    font-weight: 200;
  }
}

.our-community-numbers{
  @media (max-width: 768px){
    padding-top: 50px;
  }

  h6 {
    margin-bottom: 150px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 6px;
    span {
      font-weight: 400;
    }
    @media (max-width: 768px){
      margin-bottom: 70px;
    }
  }
  .title h1 {
    color: @color-super-dark-gray;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 0;
    span {
      font-weight: 200;
    }
  }
  .p, p {
    font-size: 22px;
    line-height: 1.4;
    font-weight: 200;
  }
  .numbers {
    @media (max-width: 768px){
      div {
        margin-top: 64px;
      }
    }
    h1 {
      font-size: 80px !important;
      span {
        font-weight: 200;
      }
    }
    h5 {
      font-size: 18px;
      color: @color-primary;
    }
  }
  
}

.our-community-partners {
  background-color: @color-light-gray;
  h3 {
    color: @color-primary;    
    line-height: normal;
    font-size: 50px;
    letter-spacing: 0;
    text-transform: none;
    span {
      font-weight: 200;
    }
  }
  .p, p {
    font-size: 18px;
    font-weight: 200;
  }
  .feature.boxed {
    background-color: @color-white;
    p {
      // margin-bottom: 24px;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    span {
      font-weight: 200;
    }
  }
}

.our-community-milk {
  background-color: @color-white;
  h2 {
    color: @color-primary;    
    line-height: normal;
    font-size: 50px;
    letter-spacing: 0;
    padding: 0 64px;
    text-transform: none;
    span {
      font-weight: 200;
    }
    @media (max-width: 768px){
      padding: 0px;
    }
  }
  h3 {
    color: @color-primary;    
    line-height: normal;
    font-size: 40px;
    letter-spacing: 0;
    span {
      font-weight: 200;
    }
  }
  .p, p {
    font-size: 18px;
    font-weight: 200;
  }
  .milk_name {
    color: @color-other-gray !important;
    display: block;
  }
  .milk_name:hover, .active .milk_name {
    color: @color-primary !important;
  }
  svg {
    path, line, circle, polyline, polygon {
      stroke: @color-other-gray;
    }
  }
  .active svg, svg:hover {
    path, line, circle, polyline, polygon {
      stroke: @color-primary;
    }
  }
  .tab-title {
    opacity: 1 !important;
  }
}

.our-community-values {
  @media (max-width: 768px){
    padding: 96px 16px;
  }
  h2 {
    color: @color-primary;    
    line-height: normal;
    font-size: 40px;
    letter-spacing: 0;
    padding: 0 64px;
    font-weight: 200;
    text-transform: none;
    margin-bottom: 16px;
    @media (max-width: 768px){
      padding: 0px;
      text-align: left;
    }
  }
  p {
    font-size: 18px;
    font-weight: 200;
    margin-bottom: 64px;
    @media (max-width: 768px){
      text-align: left;
    }
  }
  .phase {
    padding-bottom: 12px;
    height: 500px;
    .phase-inner {
      background-color: @color-white;
      margin: 4px;
      padding: 40px 20px;
      height: 100%;
    }
    h3 {
      text-transform: none !important;
      letter-spacing: 0;
      font-size: 26px;
      @media (max-width: 768px){
        text-align: left;
      }
    }
    p {
      font-size: 18px;
      font-weight: 200;
      @media (max-width: 768px){
        padding-left: 0px;
        padding-right: 16px;
        text-align: left;
      }
    }
  }
}

.our-community-white-bg{
  h2 {
    color: @color-primary;    
    line-height: normal;
    font-size: 50px;
    letter-spacing: 0;
    padding: 0 64px;
    @media (max-width: 768px){
      padding: 0px;
      text-align: left;
    }
    span {
      font-weight: 200;
    }
  }
}

.our-community-step {
  background-color: @color-white;
  color: @color-super-dark-gray !important;
  h2 {
    display: none;
  }
  h3 {
    font-size: 40px;
    color: @color-primary;
    font-family: "Josefin Sans", sans-serif;
    line-height: normal;
    span {
      font-weight: 200;
    }
  }
  .p {
    font-size: 18px;
    font-weight: 200;
  }
}


.about-us {
  .box {
    padding: 0 200px;
    @media (max-width: 768px){
      padding: 0px;
    }
    h1 {
      font-weight: 200;
      line-height: normal;
      font-size: 50px;
      letter-spacing: 0;
      
      span {
        font-weight: 200;
      }
    }
    .p, p {
      font-size: 18px;
      font-weight: 200;
    }
  }
}

.about-us-image {
  height: 380px;
  margin-bottom: 96px;
  h2 {
    line-height: normal;
    font-size: 60px;
    letter-spacing: 0;
    padding:0 80px;
    margin: 0px;
    span {
      font-weight: 200;
    }
    @media (max-width: 768px){
      padding: 0px;
      font-size: 40px;
    }
  }
}

.about-us-country {
  margin-top: 96px;
  h3 {
    font-size: 40px;
    color: @color-white !important; 
    font-family: "Josefin Sans", sans-serif;
    line-height: normal;
    letter-spacing: 0px;
    span {
      font-weight: 200;
    }
  }
  .p, p {
    color: @color-white !important;
    font-size: 20px;
    font-weight: 200;
  }
}
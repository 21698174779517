.transition(@time) {
  transition: all @time ease;
  -webkit-transition: all @time ease;
  -moz-transition: all @time ease;
}

.transition-property(@property,@time) {
  transition: @property @time ease;
  -webkit-transition: @property @time ease;
  -moz-transition: @property @time ease;
}

.transition-property-delay(@property,@time,@delay) {
  transition: @property @time ease;
  -webkit-transition: @property @time ease;
  -moz-transition: @property @time ease;
  transition-delay: @delay;
  -webkit-transition: @delay;
}

.translateX(@amount) {
  transform: translate3d(@amount, 0, 0);
  -webkit-transform: translate3d(@amount, 0, 0);
  -moz-transform: translate3d(@amount, 0, 0);
}

.translateY(@amount) {
  transform: translate3d(0, @amount, 0);
  -webkit-transform: translate3d(0, @amount, 0);
  -moz-transform: translate3d(0, @amount, 0);
}

.v-align-children {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.scale(@amount) {
  transform: scale(@amount);
  -webkit-transform: scale(@amount);
}

.v-align-children-column {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.disable-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media all and (max-width: 990px) {
  .v-align-children {
    display: block !important;
  }
}

.v-align-transform {
  position: relative;
  transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  top: 50%;
  z-index: 2;
}

.align-bottom {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 2;
}

.align-top {
  position: absolute;
  top: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 2;
}

.overlay-params(@opacity,@color) {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: @color;
  opacity: @opacity;
  z-index: 2;
}

.heading-colors(@color) {
  h1, h2, h3, h4, h5, h6 {
    color: @color;
  }
}

.text-colors(@color) {
  p, span, li {
    color: @color;
  }
}

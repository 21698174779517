.image-md {
  max-height: 120px;
}

.image-small {
  max-height: 80px;
}

.image-xs {
  max-height: 50px;
}

.image-xxs {
  max-height: 30px;
}

.fade-half {
  opacity: 0.5;
}

.fade-1-4 {
  opacity: 0.75;
}

.fade-3-4 {
  opacity: 0.25;
}

.fade-on-hover {
  .transition(0.3s);
  opacity: .5;
}

.fade-on-hover:hover {
  opacity: 1;
}

.cast-shadow {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.cast-shadow-light {
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

.image-block, .image-block .image-bg {
  padding: 0;
  height: 50vw;
  max-height: 700px;
}

.image-block .container {
  height: 100%;
}

.image-block-right .image-bg {
  position: absolute;
  top: 0;
}

@media all and (max-width: 768px) {
  .image-block {
    height: auto;
    max-height: 1000000px;
  }

  .image-block .image-bg {
    height: 350px;
  }

  .image-block .v-align-transform {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }

  .image-block-right .image-bg {
    position: relative;
  }
}

.image-edge .container {
  position: relative;
}

.image-edge .container div[class*='col-'] {
  position: absolute;
}

@media all and (max-width: 990px) {
  .image-edge .container div[class*='col-'] {
    position: relative;
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}

.image-square {
  height: 50vw;
  max-height: 640px;
  overflow: hidden;
  padding: 0;
}

.image-square .image, .image-square > .content {
  position: absolute;
}

.image-square .image {
  height: 100%;
}

.image-square > .content {
  padding: 8%;
  top: 50%;
  .translateY(-50%);
}

.image-square.left .image {
  left: 0;
}

.image-square.left > .content {
  right: 0;
}

.image-square.right .image {
  right: 0;
}

.image-square.right > .content {
  left: 0;
}

@media all and (max-width: 990px) {
  .image-square {
    height: auto;
    max-height: 100000px;
    padding-bottom: 48px;
  }

  .image-square .image, .image-square > .content {
    position: relative;
  }

  .image-square .image {
    height: 100vw;;
    margin-bottom: 48px;
    max-height: 500px;
  }

  .image-square > .content {
    top: 0;
    transform: none;
    -webkit-transform: none;
    padding: 0 20%;
  }
}

@media all and (max-width: 767px) {
  .image-square > .content {
    padding: 0 15px;
  }
}

.image-zoom img {
  .transition(9s);
}

.image-zoom:hover img {
  .scale(1.1);
}


.image-caption {
  position: relative;
  overflow: hidden;
}

.image-caption .caption {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 16px;
  color: #fff;
}

.image-caption .caption p {
  position: relative;
  z-index: 4;
  margin: 0;
}

.image-caption .caption:before {
  content: '' attr(data-caption) '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 1;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, 0.7) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(34, 34, 34, 0.7))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, 0.7) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, 0.7) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, 0.7) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, 0.7) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#e6222222',GradientType=0); /* IE6-9 */
}

.image-caption.hover-caption .caption {
  opacity: 0;
  .transition(0.3s);
  .translateY(100px);
}

.image-caption.hover-caption:hover .caption {
  opacity: 1;
  .translateY(0);
}

/*!---------- 22. IMAGE TILES ----------*/

img {
  max-width: 100%;
}

.image-tile {
  overflow: hidden;
  position: relative;
  margin-bottom: 24px;
}

.image-tile img {
  width: 100%;
  display: inline-block;
}

.image-tile .label {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 5;
}

.inner-title:before {
  .transition(.5s);
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, .9) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(34, 34, 34, .9)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, .9) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, .9) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, .9) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, .9) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#292929',GradientType=0);
  pointer-events: none;
}

.inner-title.title-center:before {
  background: @bg-dark;
  opacity: .4;
}

.inner-title:hover:before {
  .scale(1.2);
}

.inner-title .title {
  position: absolute;
  width: 100%;
  bottom: 24px;
  z-index: 5;
  .heading-colors(#fff);
  .text-colors(#fff);
  padding: 0 4px;
}

.inner-title.title-center .title {
  bottom: auto;
  top: 50%;
  .translateY(-50%);
}

.inner-title .title h5 {
  font-weight: 600;
}

.inner-title {
  .transition(0.3s);
  opacity: 1;
}

.inner-title:hover {
  opacity: .95;
}

.inner-title.hover-reveal:before {
  opacity: 0;
}

.inner-title.hover-reveal .title {
  opacity: 0;
  .translateY(50px);
  .transition(0.3s);
}

.inner-title.hover-reveal:hover .title {
  opacity: 1;
  .translateY(0px);
}

.inner-title.hover-reveal:hover:before {
  opacity: 1;
}

.outer-title img {
  display: inline-block;
  margin-bottom: 12px;
}

.hover-tile {
  position: relative;
  overflow: hidden;
  background: @bg-dark;
}

.hover-tile img {
  .transition(0.3s);
}

.hover-tile:hover img {
  opacity: .5;
}

.hover-tile .hover-state {
  .v-align-transform;
  position: absolute;
  .transition(0.3s);
  opacity: 0;
  width: 100%;
}

.hover-state {
  .heading-colors(#fff);
  .text-colors(#fff);
  z-index: 99;
  padding: 0 40px;
  cursor: default;
}

.hover-state * {
  .transition(0.3s);
  transform: translate3d(0, 30px, 0) scale(1.05);
  -webkit-transform: translate3d(0, 30px, 0) scale(1.05);
}

.hover-tile:hover .hover-state {
  opacity: 1;
}

.hover-tile:hover .hover-state * {
  transform: translate3d(0, 0px, 0) scale(1);
  -webkit-transform: translate3d(0, 0px, 0) scale(1);
}

.hover-state *:last-child {
  margin-bottom: 0;
}

.border-thick {
  border: 16px solid #fff;
  border-top: none;
}

.border-thick.col-sm-6:first-child {
  border-right: 8px solid #fff;
}

.border-thick.col-sm-6:last-child {
  border-left: 8px solid #fff;
}

.horizontal-tile {
  overflow: hidden;

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.tile-left, .tile-right {
  width: 50%;
  float: left;
  display: inline-block;
  overflow: hidden;
  min-height: 300px;
  position: relative;
}

.tile-right .description, .tile-left .description {
  padding: 48px;
}

.tile-right:hover .background-image-holder, .tile-left:hover .background-image-holder {
  .scale(1.1);
}

@media all and (max-width: 767px) {
  .tile-left, .tile-right {
    width: 100%;
    float: none;
  }
}

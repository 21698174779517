@color_1: rgba(0, 0, 0, 0.8);
@color_2: #000;
@font_family_1: "flexslider-icon";

/*
 * jQuery FlexSlider v2.3.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Ville Ristimäki (@villeristi)
 *
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
.flex-container {
  a {
    &:hover {
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }
}
.flex-slider {
  a {
    &:hover {
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }
}
.slides {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-control-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
  li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
}
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  *height: 0;
  a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: @color_1;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:before {
      font-family: @font_family_1;
      font-size: 40px;
      display: inline-block;
      content: '\f001';
    }
  }
  a.flex-next {
    &:before {
      content: '\f002';
    }
  }
  .flex-prev {
    left: -50px;
  }
  .flex-next {
    right: -50px;
    text-align: right;
  }
  .flex-disabled {
    opacity: 0 !important;
    filter: alpha(opacity=0);
    cursor: default;
  }
}
.flex-pauseplay {
  span {
    text-transform: capitalize;
  }
  a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: @color_2;
    &:before {
      font-family: @font_family_1;
      font-size: 20px;
      display: inline-block;
      content: '\f004';
    }
    &:hover {
      opacity: 1;
    }
    .flex-play {
      &:before {
        content: '\f003';
      }
    }
  }
}
.flexslider {
  margin: 0;
  padding: 0;
  margin: 0 0 60px;
  background: @color-white;
  border: 4px solid @color-white;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  .slides {
    >li {
      display: none;
      -webkit-backface-visibility: hidden;
    }
    img {
      width: 100%;
      display: block;
    }
    &:after {
      content: "\0020";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
    zoom: 1;
  }
  &:hover {
    .flex-direction-nav {
      .flex-prev {
        opacity: 0.7;
        left: 10px;
        &:hover {
          opacity: 1;
        }
      }
      .flex-next {
        opacity: 0.7;
        right: 10px;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
html[xmlns] {
  .flexslider {
    .slides {
      display: block;
    }
  }
}
* {
  html {
    .flexslider {
      .slides {
        height: 1%;
      }
    }
  }
}
.no-js {
  .flexslider {
    .slides {
      >li {
        &:first-child {
          display: block;
        }
      }
    }
  }
}
.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.loading {
  .flex-viewport {
    max-height: 300px;
  }
}
.carousel {
  li {
    margin-right: 5px;
  }
}
.flex-control-paging {
  li {
    a {
      width: 11px;
      height: 11px;
      display: block;
      background: #666;
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      text-indent: -9999px;
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      &:hover {
        background: #333;
        background: rgba(0, 0, 0, 0.7);
      }
    }
    a.flex-active {
      background: #000;
      background: rgba(0, 0, 0, 0.9);
      cursor: default;
    }
  }
}
.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
  li {
    width: 25%;
    float: left;
    margin: 0;
  }
  img {
    width: 100%;
    display: block;
    opacity: .7;
    cursor: pointer;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    &:hover {
      opacity: 1;
    }
  }
  .flex-active {
    opacity: 1;
    cursor: default;
  }
}
@media screen and (max-width: 860px) {
  .flex-direction-nav {
    .flex-prev {
      opacity: 1;
      left: 10px;
    }
    .flex-next {
      opacity: 1;
      right: 10px;
    }
  }
}

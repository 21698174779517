input, button, select, textarea {
  font-family: @heading-font, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

input[type="text"], button, textarea, select, input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.bg-secondary input[type="text"], .bg-secondary textarea, .bg-secondary select {
  background: #fff;
}

.input-lh {
  line-height: 50px;
}

.attempted-submit .field-error {
  outline: 1px red !important;
}

.input-with-label span {
  font-family: @heading-font, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  color: @bg-dark;
  letter-spacing: 1px;
  font-weight: 700;
  display: block;
  cursor: default;
}

input[type="text"], input[type="password"] {
  background: #f5f5f5;
  border: none;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  border-radius: 0;
}

.image-bg input[type="text"], .image-bg textarea {
  color: #555;
}

.image-bg.bg-light input.transparent, .image-bg.bg-light button.transparent {
  border-color: @bg-dark;
}

input.transparent, button.transparent {
  background: none;
  border: 1px solid rgba(255, 255, 255, .5);
  color: #fff !important;
}

input[type="text"]:focus, input[type="password"]:focus {
  outline: 1px solid #ccc;
}

textarea {
  width: 100%;
  border: none;
  background: #f5f5f5;
  margin-bottom: 24px;
  border-radius: 0;
  padding: 16px 20px;
}

textarea:focus {
  outline: 1px solid #ccc;
}

::-webkit-input-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #777;
  font-size: 11px;
}

:-moz-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #777;
  font-size: 11px;
}

::-moz-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #777;
  font-size: 11px;
}

:-ms-input-placeholder {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #777;
  font-size: 11px;
}

input.transparent::-webkit-input-placeholder, button.transparent::-webkit-input-placeholder {
  color: #fff;
}

input.transparent::-moz-input-placeholder, button.transparent::-moz-input-placeholder {
  color: #fff;
}

input.transparent:-moz-input-placeholder, button.transparent:-moz-input-placeholder {
  color: #fff;
}

input.transparent:-ms-input-placeholder, button.transparent:-ms-input-placeholder {
  color: #fff;
}

input[type="submit"], button[type="submit"] {
  height: 50px;
  line-height: 48px;
  border: 2px solid @color-primary;
  background: @color-primary;
  color: #fff;
  width: 100%;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 0 !important;
}

.bg-primary input[type="submit"], .bg-primary button[type="submit"] {
  background: #fff;
  color: @color-primary;
}

input[type="submit"]:focus, button[type="submit"]:focus {
  outline: none;
}

input[type="submit"].hollow, button[type="submit"].hollow {
  background: none;
  border: 2px solid @color-primary;
  color: @color-primary;
  .transition(0.3s);
}

input[type="submit"].hollow:hover, button[type="submit"].hollow:hover {
  background: @color-primary;
  color: #fff;
}

.select-option {
  position: relative;
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  margin-bottom: 24px;
}

.select-option i {
  position: absolute;
  font-size: 18px;
  right: 20px;
  top: 14px;
  z-index: 2;
  pointer-events: none;
  .transition(0.3s);
  cursor: pointer;
}

.select-option:focus i {
  color: #fff;
}

.select-option select {
  margin-bottom: 0;
}

select {
  height: 50px;
  background: #f5f5f5;
  width: 100%;
  border-radius: 0;
  border: none;
  outline: none;
  padding-left: 20px;
  position: relative;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #777;
  .transition(0.3s);
  cursor: pointer;
}

select:focus {
  background: #eee;
}

select:active, select:focus, select:hover {
  outline: none;
  border: none;
}

.checkbox-option, .radio-option {
  display: inline-block;
  width: 50px;
  height: 25px;
  border-radius: 25px;
  border: 1px solid @color-primary;
  cursor: pointer;
  .disable-select;
  margin-bottom: 24px;
}

.checkbox-option .inner, .radio-option .inner {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  border: 1px solid @color-primary;
  position: relative;
  top: 2px;
  left: 2px;
  display: inline-block;
  .transition(0.2s);
}

.checkbox-option.checked .inner {
  .translateX(25px);
  background: @color-primary;
}

.checkbox-option input {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.radio-option {
  width: 25px;
  height: 25px;
  text-align: left;
}

.radio-option:nth-of-type(n+2) {
  margin-left: 24px;
}

.radio-option input {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.radio-option .inner {
  border: none;
  width: 19px;
  height: 19px;
  left: 2px;
  .scale(0);
}

.radio-option + span {
  display: inline-block;
  line-height: 25px;
}

.radio-option.checked .inner {
  .scale(1);
}

.radio-option.checked .inner {
  background: @color-primary;
}

@media all and (min-width: 991px) {

  input.col-md-6, button.col-md-6 {
    width: 49%;
    float: left;
  }

  input.col-md-6:first-of-type, button.col-md-6:first-of-type {
    margin-right: 1%;
  }

  input.col-md-6:last-of-type, button.col-md-6:last-of-type {
    margin-left: 1%;
  }

}

form.thirds input, form.thirds button {
  width: 32%;
  float: left;
  margin-left: 1%;
  margin-bottom: 16px;
}

form.halves input, form.halves button {
  width: 46%;
  float: left;
  margin-left: 2.5%;
  margin-bottom: 16px;
}

form.halves p, form.thirds p, form.halves span, form.thirds span {
  clear: both;
}

.form-error, .form-success {
  background: #58ce38;
  padding: 12px;
  width: 80%;
  color: #fff;
  clear: both;
  display: block;
  max-width: 700px;
  position: relative;
  top: 16px;
  margin: 0 auto;
  word-break: break-word;
}

.form-error {
  background: #ce3838;
}

.form-error a, .form-success a {
  display: block;
  color: #fff;
}

.attempted-submit .field-error {
  outline: 1px solid #ce3838 !important;
}

form iframe.mail-list-form {
  display: none;
}

.form-loading {
  border: 3px solid rgba(255, 255, 255, 1);
  border-radius: 30px;
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  opacity: 0;
  margin: 0px auto;
  top: 50%;
  width: 30px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: pulsate 1s ease-out;
  -moz-animation-iteration-count: infinite;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  z-index: 99999;
}

@keyframes pulsate {
  0% {
    transform: scale(.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@-webkit-keyframes pulsate {
  0% {
    transform: scale(.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

button[type="submit"] .form-loading {
  height: 25px;
  width: 25px;
  display: inline-block;
  top: 9px;
  position: relative;
  left: 0;
}

.btn-white .form-loading {
  border: 3px solid rgba(200, 200, 200, 1);
}


@media all and (max-width: 767px) {
  form.thirds input, form.halves input, form.thirds button, form.halves button {
    width: 100%;
    margin-bottom: 8px;
    float: left;
    margin-left: 0;
  }
}

.map-holder {
  overflow: hidden;
  position: relative;
}

.map-holder iframe {
  border: none;
  position: absolute;
  width: 100%;
  height: 180%;
  top: -40%;
  left: 0;
  z-index: 1;
}

.map-holder:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.map-holder.interact:before {
  width: 0;
}

.map-holder.inline {
  height: 400px;
  overflow: hidden;
}

.map-holder.inline iframe {
}

.map-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

